.LandingPage-Section {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: none;
}

.LandingPage-SectionH1 {
  text-align: center;
  font-size: 35px;
  color: white;
  font-style: italic;
  font-weight: bold;
  width: 50%;
  margin: auto;
  margin-top: -20px;
}

.testimonial {
  position: relative;
  max-width: 900px;
  width: 100%;
  padding: 50px 0;
  overflow: hidden;
}
.testimonial .image {
  height: 150px;
  width: 150px;
  object-fit: cover;
  border-radius: 50%;
}
.testimonial .slide {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 30px;
  height: 100%;
  width: 100%;
}
.slide p {
  text-align: center;
  padding: 0 100px;
  font-size: 17px;
  font-weight: 400;
  color: white;
  text-shadow: 1px 1px 5px rgba(246, 241, 241, 0.6);
}
.slide .quote-icon {
  font-size: 30px;
  color: #4070f4;
}
.slide .details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.details .name {
  font-size: 19px;
  font-weight: 700;
  color: white;
}
.details .job {
  font-size: 17px;
  font-weight: 500;
  font-style: italic;
  color: white;
}
/* swiper button css */
.nav-btn {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  transform: translateY(30px);
  background-color: rgba(0, 0, 0, 0.1);
  transition: 0.2s;
}
.nav-btn:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.nav-btn::after,
.nav-btn::before {
  font-size: 20px;
  color: #fff;
}
.swiper-pagination-bullet {
  background-color: rgba(209, 208, 208, 0.8);
  height: 20px;
  width: 20px;
}
.swiper-pagination-bullet-active {
  background-color: #d8c4e7;
}
@media screen and (max-width: 768px) {
  .slide p {
    padding: 0 20px;
  }
  .nav-btn {
    display: none;
  }
}
